<template>
  <slideshow
    :slides="usedSlides"
    :slidesToShow="1"
    :hasDots="true"
    :leftButton="'/assets/icons/white/chevron_left.svg'"
    :rightButton="'/assets/icons/white/chevron_right.svg'"
    :paddingDefault="'px-0'"
    :hasAnimations="false"
  >
    <template #content="{ slide }">
      <section v-if="slide.toggle" class="py-0 w-100">
        <div class="row mobile-md m-0">
          <div
            class="col-12 p-0 editorial-mobile-img"
            :style="{ 'background-image': 'url('+slide.image+')' }"
          />
          <div
            class="block-body col-12 mx-auto px-4 py-3"
            :style="{ 'background-color': slide['body-bg-color'] }"
          >
            <h3
              :class="{
                'secondary-display t-center t-primary line-bottom line-center mb-5 lett-spacing lg mt-3': true,
                [slide['line-color']]: true,
              }"
            >
              {{ slide.title }}
            </h3>
            <p class="t-black primary">
              {{ slide.body }}
            </p>
            <a
              v-if="slide.btn && slide.btn.active"
              :class="{'btn square lg-btn m-xs mr-3 mt-2' : true,
                       ['btn-'+slide.btn.type]: true
              }"
              style="left: -2rem;"
              :href="slide.btn.link"
            >{{ slide.btn.txt }}</a>
          </div>
        </div>
        <div class="desktop-md">
          <div class="row m-0" v-if="slide['desktop-image-side'] === 'left'">
            <div
              :class="{
                'col-6 p-0': true,
                ['editorial-desktop-img-'+slide['desktop-image-size']]: true
              }"
              :style="{ 'background-image': 'url('+slide.image+')' }"
            />
            <div class="col-6 px-5 py-4 pt-5" :style="{ 'background-color': slide['body-bg-color'] }">
              <h3 
                :class="{
                  'secondary-display t-primary line-bottom mb-5 lett-spacing lg headers-line-height': true,
                  [slide['line-color']]: true,
                }"
              >
                {{ slide.title }}
              </h3>
              <p class="t-black primary md pr-4">
                {{ slide.body }}
              </p>
              <a
                v-if="slide.btn && slide.btn.active"
                :class="{'btn square lg-btn m-xs mr-3 mt-2' : true,
                         ['btn-'+slide.btn.type]: true
                }"
                style="left: -2rem;"
                :href="slide.btn.link"
              >{{ slide.btn.txt }}</a>
            </div>
          </div>
          <div class="row m-0" v-else>
            <div
              class="col-6 pr-5 py-4"
              :style="{ 'background-color': slide['body-bg-color'],
                        'padding-left':' 4rem !important'
              }"
            >
              <h3
                :class="{
                  'secondary-display t-primary line-bottom mb-5 lett-spacing lg pt-4 headers-line-height': true,
                  [slide['line-color']]: true,
                }"
              >
                {{ slide.title }}
              </h3>
              <p class="t-black primary md">
                {{ slide.body }}
              </p>
              <a
                v-if="slide.btn && slide.btn.active"
                :class="{'btn square lg-btn m-xs mr-3 mt-2' : true,
                         ['btn-'+slide.btn.type]: true
                }"
                style="left: -2rem;"
                :href="slide.btn.link"
              >{{ slide.btn.txt }}</a>
            </div>
            <div
              :class="{
                'col-6 p-0': true,
                ['editorial-desktop-img-'+slide['desktop-image-size']]: true
              }"
              :style="{ 'background-image': 'url('+slide.image+')' }"
            />
          </div>
        </div>
      </section>
    </template>
  </slideshow>
</template>
<script>
import Slideshow from '../utils/slideshow/slideshow.vue'
export default {
	name: 'EditorialCarousel',
	components: { Slideshow },
	props: {
		blocks: { type: String, required: true },
	},
	computed: {
		usedSlides() {
			var slides = JSON.parse(this.blocks.replaceAll('=>', ':'))
			var i
			for (i = 0; i < slides.length; i++) {
				slides[i]['index'] = i
			}
			return slides
		},
	},
}
</script>
<style></style>
